import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import ScrollIndicator from '../../../elements/scrollIndicator';
import { Tag } from '../../../icons/tag'
import { HeroBanner } from '../../../elements/heroBanner'
import styles from './styles.module.scss'
import { Container, Row, Col } from '../../../elements/grid'

const CurvedTitle: React.FC = () => (
  <div className={styles.curvedTextContainer}>
    <svg viewBox="0 0 1000 400" className="curvedSvg">
      <path id="curve" d="M50,300 Q500,-200 950,300" fill="transparent"/>
      <text width="1000" className={styles.curvedText}>
        <textPath href="#curve" startOffset="50%" textAnchor="middle">
          PURSUE EMOTIONAL FITNESS
        </textPath>
      </text>
    </svg>
  </div>
);
export const Banner: React.FC = () => {
  return (
  <div className={styles.banner}>
    <div className={styles.homeBanner}>
      <HeroBanner
        type={'video'}
        source={'/static/banner/624_banner.mp4'}
        poster={'/static/banner/efit_banner.png'}
      />
      <div className={styles.title}>
        <CurvedTitle />

      </div>
    </div>
    <div className={styles.bottomBanner}>
      <Container>
        <Row>
          <Col>
            <p className={styles.bottomBannerText}>
              eFIT is the first membership-based mental health care model in
              Canada that incorporates psychological, physical, and social
              aspects of health all under one roof for one price.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
    <ScrollIndicator link="#community" />
  </div>
  )
}
