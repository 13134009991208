import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import styles from './styles.module.scss';

export const Community: React.FC = () => {
  const [activeTab, setActiveTab] = useState('community');

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div id="community" className={styles.community}>
      <div className="row w-100">
        <div className="col-md-6">
          <div className="tab-content" id="myTabContent">
            <div className={`tab-pane fade ${activeTab === 'community' ? 'show active' : ''}`} id="community" role="tabpanel" aria-labelledby="community-tab">
              <div className={`${styles.textContainer} text-white p-5`}>
                <h1 className="font-weight-bold">COMMUNITY</h1>
                <p>
                  Is dolupis quid quia voluptis sed miliquatum estrum ate optiam re endi alis rem de pera doluptas
                  eum num facium? Escis et facerante sit aut est repe comni que voloribus et reptaspero venis et am
                  rem nistius.
                </p>
                <p>
                  Nequation escillorae lia dolorep taspis sa aut mi re, tem idit, serorum quiaer nam suntuscitium...
                </p>
                <button className="btn btn-primary more-button">MORE</button>
              </div>
            </div>
            <div className={`tab-pane fade ${activeTab === 'functionalMovement' ? 'show active' : ''}`} id="functionalMovement" role="tabpanel" aria-labelledby="functionalMovement-tab">
              <div className={`${styles.textContainer} text-white p-5`}>
                <h1 className="font-weight-bold">FUNCTIONAL MOVEMENT</h1>
                <p>
                  Functional Movement content goes here. This is the content for the Functional Movement tab.
                </p>
                <button className="btn btn-primary more-button">MORE</button>
              </div>
            </div>
            <div className={`tab-pane fade ${activeTab === 'somaticClasses' ? 'show active' : ''}`} id="somaticClasses" role="tabpanel" aria-labelledby="somaticClasses-tab">
              <div className={`${styles.textContainer} text-white p-5`}>
                <h1 className="font-weight-bold">SOMATIC CLASSES</h1>
                <p>
                  Somatic Classes content goes here. This is the content for the Somatic Classes tab.
                </p>
                <button className="btn btn-primary more-button">MORE</button>
              </div>
            </div>
            <div className={`tab-pane fade ${activeTab === 'nutritionalSupport' ? 'show active' : ''}`} id="nutritionalSupport" role="tabpanel" aria-labelledby="nutritionalSupport-tab">
              <div className={`${styles.textContainer} text-white p-5`}>
                <h1 className="font-weight-bold">NUTRITIONAL SUPPORT</h1>
                <p>
                  Nutritional Support content goes here. This is the content for the Nutritional Support tab.
                </p>
                <button className="btn btn-primary more-button">MORE</button>
              </div>
            </div>
            <div className={`tab-pane fade ${activeTab === 'deepEmotionalProcessing' ? 'show active' : ''}`} id="deepEmotionalProcessing" role="tabpanel" aria-labelledby="deepEmotionalProcessing-tab">
              <div className={`${styles.textContainer} text-white p-5`}>
                <h1 className="font-weight-bold">DEEP EMOTIONAL PROCESSING</h1>
                <p>
                  Deep Emotional Processing content goes here. This is the content for the Deep Emotional Processing tab.
                </p>
                <button className="btn btn-primary more-button">MORE</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <div className={styles.logoContainer}>
            <img src="/static/logo-white.svg" alt="Fit Logo" className="img-fluid" style={{ maxWidth: '50%' }} />
            <svg className={styles.tabTitles} viewBox="0 0 300 300">
              <defs>
                <path id="circlePath" d="M 150, 150 m -130, 0 a 130,130 0 1,1 260,0 a 130,130 0 1,1 -260,0" />
              </defs>
              <a href="#community" id="community-tab" onClick={() => handleTabClick('community')} className={activeTab === 'community' ? styles.activeTab : ''}>
                <text textAnchor="middle">
                  <textPath href="#circlePath" startOffset="0%">Community</textPath>
                </text>
              </a>
              <a href="#functionalMovement" id="functionalMovement-tab" onClick={() => handleTabClick('functionalMovement')} className={activeTab === 'functionalMovement' ? styles.activeTab : ''}>
                <text textAnchor="middle">
                  <textPath href="#circlePath" startOffset="15%">Functional Movement</textPath>
                </text>
              </a>
              <a href="#somaticClasses" id="somaticClasses-tab" onClick={() => handleTabClick('somaticClasses')} className={activeTab === 'somaticClasses' ? styles.activeTab : ''}>
                <text textAnchor="middle">
                  <textPath href="#circlePath" startOffset="45%">Somatic Classes</textPath>
                </text>
              </a>
              <a href="#nutritionalSupport" id="nutritionalSupport-tab" onClick={() => handleTabClick('nutritionalSupport')} className={activeTab === 'nutritionalSupport' ? styles.activeTab : ''}>
                <text textAnchor="middle">
                  <textPath href="#circlePath" startOffset="65%">Nutritional Support</textPath>
                </text>
              </a>
              <a href="#deepEmotionalProcessing" id="deepEmotionalProcessing-tab" onClick={() => handleTabClick('deepEmotionalProcessing')} className={activeTab === 'deepEmotionalProcessing' ? styles.activeTab : ''}>
                <text textAnchor="middle">
                  <textPath href="#circlePath" startOffset="85%">Deep Emotional Processing</textPath>
                </text>
              </a>
            </svg>
          </div>
        </div>
          </div>
        </div>
  );
}