import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface ScrollIndicatorProps {
  link: string;
}

const ScrollIndicator: React.FC<ScrollIndicatorProps> = ({ link }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = () => {
    const element = document.querySelector(link);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const element = document.querySelector(link);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      if (scrollPosition > elementPosition) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    isVisible ? (
      <div className={styles.scrollIndicator} onClick={handleClick}>
        <div className={styles.scrollBar}></div>
      </div>
    ) : null
  );
};

export default ScrollIndicator;