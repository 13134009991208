import React from 'react';
import styles from './styles.module.scss';

const Experience: React.FC = () => {
  return (
    <div className={styles.experienceContainer}>
      <h1 className={styles.title}>
        WHAT <span className={styles.highlight}>E FIT</span> MEMBERS EXPERIENCE
      </h1>
      <svg viewBox="0 0 1000 600" className={styles.curvedTextContainer}>
        <path id="curve1" d="M 100,100 C 300,50 700,50 900,100" fill="transparent" />
        <path id="curve2" d="M 100,150 C 300,100 700,100 900,150" fill="transparent" />
        <path id="curve3" d="M 100,200 C 300,150 700,150 900,200" fill="transparent" />
        <path id="curve4" d="M 100,250 C 300,200 700,200 900,250" fill="transparent" />
        <path id="curve5" d="M 100,300 C 300,250 700,250 900,300" fill="transparent" />
        <path id="curve6" d="M 100,350 C 300,300 700,300 900,350" fill="transparent" />
        <path id="curve7" d="M 100,400 C 300,350 700,350 900,400" fill="transparent" />
        <path id="curve8" d="M 100,450 C 300,400 700,400 900,450" fill="transparent" />
        <path id="curve9" d="M 100,500 C 300,450 700,450 900,500" fill="transparent" />
        <path id="curve10" d="M 100,550 C 300,500 700,500 900,550" fill="transparent" />
        <path id="curve11" d="M 100,600 C 300,550 700,550 900,600" fill="transparent" />
        
        <text width="500">
          <textPath href="#curve1" startOffset="50%">
            1. Online Assessment
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve2" startOffset="50%">
            2. Health Review with Intake Coordinator and Nurse
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve3" startOffset="50%">
            3. Nutrition Assessment with Dietitian
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve4" startOffset="50%">
            4. Weekly Trauma and Emotional Processing with Psychologists
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve5" startOffset="50%">
            5. Weekly Psychology and Dietitian Supported Nutritional Counseling
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve6" startOffset="50%">
            6. Daily Osteopath-led Somatic Classes
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve7" startOffset="50%">
            7. Movement Assessment with Head Coach and Physiotherapist
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve8" startOffset="50%">
            8. Personalized Programming and Gym Membership
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve9" startOffset="50%">
            9. Supportive Coaching Staff
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve10" startOffset="50%">
            10. Community
          </textPath>
        </text>
        <text width="500">
          <textPath href="#curve11" startOffset="50%">
            11. Regular Monitoring of Progress
          </textPath>
        </text>
      </svg>
    </div>
  );
};

export default Experience;