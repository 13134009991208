import React from 'react'
import { Helmet } from 'react-helmet'
import { Banner } from './banner'
import { Affiliates, Layout, Newsletter } from '../../layout'
import { Community } from './community'
import Experience from './experience'
import { ScrollRestoration } from 'react-router-dom'

export const Home = (): JSX.Element => {
  return (
    <Layout fullHeight>
      <Helmet>
        <title>eFIT | Mental Health | Dr. Katherine Gardhouse</title>
        <link rel="canonical" href="https://efit.institute/" />
        <meta name="description" content="eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
        <meta property="og:title" content="eFIT - Mental Health - Dr. Katherine Gardhouse" />
        <meta property="og:description" content="eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
        <meta property="og:image" content="/static/efit-logo.webp" />
        <meta property="og:url" content={`https://efit.institute/`} /> 
        <meta itemProp="name" content="eFIT - Mental Health - Dr. Katherine Gardhouse" />
        <meta itemProp="url" content={`https://efit.institute/`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="eFIT - Mental Health - Dr. Katherine Gardhouse" />
        <meta name="twitter:description" content="eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse"/>
        <meta name="twitter:image" content="/static/efit-logo.webp" />
      </Helmet>
      <Banner />
      <Community />
      <Experience />
      <ScrollRestoration />
    </Layout>
  )
}
