import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'
import { Container, Row, Col } from '../../../elements/grid'


interface iTeamData {
  shortName: string
  imagePath: string
  slug: string
  title: string
  role: string
}


const teamData: iTeamData[] = [
  {
    shortName: 'katherine',
    slug: 'katherine-gardhouse',
    title: 'Dr. Katherine Gardhouse, Ph.D., C.Psych.',
    role: 'Director & Founder, Clinical & Health Psychologist',
    imagePath: '/static/team/katherine_headshot.webp'
  }, {
    shortName: 'tara',
    slug: 'tara-gralnick',
    title: 'Tara Gralnick, Ph.D. (Supervised Practice)',
    role: 'Cofounder',
    imagePath: '/static/team/tara_headshot.webp'
  }, {
    shortName: 'erin',
    slug: 'erin-oflaherty',
    title: 'Erin O\'Flaherty',
    role: 'RD, Registered Dietitian, Certified Intuitive Eating Counsellor',
    imagePath: '/static/team/erin_headshot.webp'
  }, {
    shortName: 'laura',
    slug: 'laura-sorbara',
    title: 'Laura Sorbara',
    role: 'Volunteer Coordinator',
    imagePath: '/static/team/laura_headshot.webp'
  },
  {
    shortName: 'jess',
    slug: 'jessica-vlasak',
    title: 'Jessica Vlasak, RN',
    role: "Operational Manager and Intake Coordinator",
    imagePath: "/static/team/jess_headshot.webp"
  },
  {
    shortName: 'dan',
    slug: 'dan-hamilton',
    title: 'Daniel Hamilton',
    role: "Cofounder, Technical & Operations",
    imagePath: "/static/team/dan_headshot.webp"
  }
]

export const Team: React.FC = () => {
  return (
    <div className={styles.team}>
      <Container>
        <Row>
          <Col>
            <h2>The team</h2>
          </Col>
        </Row>
        <Row>
          {teamData.map((data: iTeamData) => {
            return (
              <Col size={12} tabletSize={6} desktopSize={4} className={styles.teamItem} key={data.title}>
                <Link to={`/team/${data.slug}/`}>
                  <div className={styles.image}>
                    <img src={data.imagePath} alt={data.title} />
                  </div>
                  <h3>{data.title}</h3>
                  <p>{data.role}</p>
                </Link>
              </Col>
            )
          })}
        </Row>
        <Row>
          <Col>
            <Link className={styles.button} to="/team">Get to know the team</Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
