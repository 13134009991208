import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface iNavTriggerProps {
  isOpen: boolean;
  onTrigger: () => void;
}

const NavigationTrigger: React.FC<iNavTriggerProps> = ({ isOpen, onTrigger }) => {
  const classes: string = classNames({
    [styles.navExpand]: true,
    [styles.navExpandIsOpen]: isOpen,
  });

  return (
    <button aria-label="Navigation" className={classes} onClick={onTrigger}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.hamburgerIcon}
      >
        <rect y="5" width="30" height="5" rx="1.5" fill="white" />
        <rect y="13.5" width="30" height="5" rx="1.5" fill="white" />
        <rect y="22" width="30" height="5" rx="1.5" fill="white" />
      </svg>
    </button>
  );
};

export const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleNavTrigger = (): void => {
    setIsOpen(!isOpen);
  };
  const classes: string = classNames({
    [styles.nav]: true,
    [styles.navIsOpen]: isOpen,
  });
  const location = useLocation();
  const path = location.pathname;
  const teamPage = path.includes('/team');
  const locationPage = path.includes('/location');
  const membershipPage = path.includes('/membership');
  const contactusPage = path.includes('/contact-us');
  const home = !teamPage && !locationPage && !membershipPage && !contactusPage;

  return (
    <>
      <div className="navbar-header ml-auto">
        <nav className="navbar navbar-light">
          <NavigationTrigger isOpen={isOpen} onTrigger={handleNavTrigger} />
        </nav>
      </div>
      <div className={classNames("collapse navbar-collapse ml-auto", { show: isOpen })} id="navbarToggleExternalContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink title="Home - eFIT" to="/" className={classNames("nav-link", { [styles.active]: home })}>Home</NavLink>
          </li>
          <li className="nav-item">
            <NavLink title="Team - eFIT" to="/team/" className={classNames("nav-link", { [styles.active]: teamPage })}>Team</NavLink>
          </li>
          <li className="nav-item">
            <NavLink title="Location - eFIT" to="/location/" className={classNames("nav-link", { [styles.active]: locationPage })}>Location</NavLink>
          </li>
          <li className="nav-item">
            <NavLink title="Membership - eFIT" to="/membership/" className={classNames("nav-link", { [styles.active]: membershipPage })}>Membership</NavLink>
          </li>
          <li className="nav-item">
            <NavLink title="Contact Us - eFIT" to="/contact-us/" className={classNames("nav-link", { [styles.active]: contactusPage })}>Contact Us</NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};